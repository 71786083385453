<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.driver_assign') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.organization')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.batch_no')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.batch_no"
                          :options="batchList"
                          id="batch_no"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' :  $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                        >
                        <template v-slot:first>
                          <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tpm.driver_assign') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="driver-assign-form" :class="'btn btn_add_new'">
                        <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                      </router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(fiscal_year)="data">
                                        {{ getFiscalYear(data.item.fiscal_year_id) }}
                                        </template>
                                        <template v-slot:cell(circular_memo_no)="data">
                                        {{ data.item.circular_memo_no }}
                                        </template>
                                        <template v-slot:cell(training_title)="data">
                                        {{ getTrainingTitleName(data.item.training_title_id) }}
                                        </template>
                                        <template v-slot:cell(batch_no)="data">
                                        {{ getBatchData(data.item.batch_no) }}
                                        </template>
                                        <!-- <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template> -->
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-4 @click="edit(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </a>
                                            <router-link title="Edit" :to="`/training-e-learning-service/tpm/driver-assign-form?id=${data.item.id}`" class="btn_table_action table_action_edit"><i class="ri-ball-pen-fill m-0"></i></router-link>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                                <!-- <pre> trainer Name : {{ getTrainerName(226) }}</pre> -->
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.driver_assign') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
  </template>
  <script>
  import { core } from '@/config/pluginInit'
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { allTrainerData, driverAssignList, circularList } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/modal-base-master-list'
  import Store from '@/store'
import Details from './Details'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          circularLoading: false,
          trainerLoading: false,
          search: {
            circular_memo_no: '',
            batch_no: 0,
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0
          },
          editItemId: '',
          trainingTypeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          allData: [],
          circularList: [],
          batchList: [],
          allBatchListData: []
        }
    },
    computed: {
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      // trainingTypeList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      // },
      // courseModuleList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.courseModuleList.filter(item => item.status === 1)
      // },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('elearning_config.update')
      },
      trainerList: function () {
        const listObject = this.trainerListData
        return listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
          }
        })
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
              // { label: this.$t('elearning_tpm.course_module'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]
          let keys = []
          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'circular_memo_no' },
                { key: 'training_title' },
                { key: 'batch_no' },
                // { key: 'course_module_bn' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'circular_memo_no' },
                { key: 'training_title' },
                { key: 'batch_no' },
                // { key: 'course_module' },
                { key: 'action' }
              ]
          }
          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId,
            org_id: this.$store.state.dataFilters.orgId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.search.org_id = this.$store.state.Auth.authUser.org_id
        }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.fiscal_year_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.circular_memo_no = ''
          this.search.batch_no = 0
          this.getCircularList()
        }
      },
     'search.org_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_type_id = 0
          this.trainingTypeList = this.getTypeList(newValue)
        } else {
          this.trainingTypeList = []
        }
      },
      'search.training_type_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_category_id = 0
          this.trainingCategoryList = this.getCategoryList(newValue)
        } else {
          this.trainingCategoryList = []
        }
      },
      'search.training_category_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_title_id = 0
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        } else {
          this.trainingTitleList = []
        }
      },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.search.circular_memo_no = ''
          this.search.batch_no = 0
          this.getCircularList()
        }
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.batch_no = allBatchListData.batch_no
          }
        }
      },
      'search.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.circular_memo_no = allBatchListData.circular_memo_no
          }
        }
      }
    },
    methods: {
      async getCircularList () {
        if (this.search.fiscal_year_id && this.search.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.search.fiscal_year_id,
            training_type_id: this.search.training_type_id,
            training_category_id: this.search.training_category_id,
            training_title_id: this.search.training_title_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
          } else {
            this.allBatchListData = result.batchList
            this.batchInfo()
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      getTrainingTitleName (id) {
        const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      edit (item) {
        this.editItemId = item.id
      },
      getFiscalYear (id) {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === id)
        if (this.$i18n.locale === 'bn') {
          return fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
        } else {
          return fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
        }
      },
      getBatchData (id) {
        const trainingType = this.allBatchList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getTrainerName (id) {
        const trainingType = this.trainerList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getTypeList (orgId) {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (orgId) {
            return type.filter(item => item.org_id === orgId)
          }
          return type
        }
      },
      async getOrgTrainerList () {
        this.trainerLoading = true
        const params = {
          org_id: this.search.resource_org_id,
          profession_type: this.search.profession_type
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
        if (!result.success) {
          this.trainerListData = []
        } else {
          this.trainerListData = result.data
        }
        this.trainerLoading = false
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        if (this.search.training_type_id) {
          const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
          if (typeId) {
            return trainingCategoryList.filter(item => item.training_type_id === typeId)
          }
          return trainingCategoryList
        }
      },
      getTrainingTitleList (categoryId) {
        if (this.search.training_category_id) {
          const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
          if (categoryId) {
            return trainingTitleList.filter(item => item.training_category_id === categoryId)
          }
          return trainingTitleList
        }
      },
      getCourseModuleList (categoryId) {
        if (this.search.training_title_id) {
          const trainingTitleList = this.$store.state.TrainingElearning.commonObj.courseModuleList.filter(item => item.status === 1)
          if (categoryId) {
            return trainingTitleList.filter(item => item.training_title_id === categoryId)
          }
          return trainingTitleList
        }
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, driverAssignList, params)
        if (result.success) {
          const listData = result.data.data
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const trainerNameObj = this.trainerList.find(doc => doc.value === parseInt(item.personal_info_id))
          const trainerNameData = {}
          if (typeof trainerNameObj !== 'undefined') {
            trainerNameData.trainer_name = trainerNameObj.text_en
            trainerNameData.trainer_name_bn = trainerNameObj.text_bn
          } else {
            trainerNameData.trainer_name = ''
            trainerNameData.trainer_name_bn = ''
          }
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.resource_org_id))
          const orgNameData = {}
          if (typeof orgObj !== 'undefined') {
            orgNameData.org_name = orgObj.text_en
            orgNameData.org_name_bn = orgObj.text_bn
          } else {
            orgNameData.org_name = ''
            orgNameData.org_name_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const moduleObj = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(courseModuleList => courseModuleList.value === parseInt(item.course_module_id))
          const moduleData = {}
          if (typeof moduleObj !== 'undefined') {
            moduleData.course_module = moduleObj.text_en
            moduleData.course_module_bn = moduleObj.text_bn
          } else {
            moduleData.course_module = ''
            moduleData.course_module_bn = ''
          }
          return Object.assign({}, item, orgNameData, trainerNameData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, moduleData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
  }
  </script>
